import APIService from './APIService';

export default {
  getGruposRelatorios() {
    return APIService.apiCall().get('/grupos-relatorios/index');
  },
  getGrupoRelatorio(id) {
    return APIService.apiCall().get(
      `/grupos-relatorios/get-grupo-relatorio/${id}`
    );
  },
  deletar(id) {
    return APIService.apiCall().get(`/grupos-relatorios/excluir/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post(
      '/grupos-relatorios/save',
      JSON.stringify(postData)
    );
  }
};
