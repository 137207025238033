<template>
  <v-container
    fluid
    class="pt-0"
  >
    <base-material-card
      color="primary"
      icon="mdi-file-table-box-multiple-outline"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Grupo de Relatórios</div>
      </template>
      <v-btn
        id="btn-novo-grupo-relatorio"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.replace('edit')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Novo Grupo
      </v-btn>
      <v-text-field
        v-model="search"
        id="grupos-relatorios-historico-search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />
      <v-divider class="mt-10" />
      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhum relatório disponível"
      >
        <template v-slot:[`item.nome`]="{ item }">
          {{ item.nome }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            id="action-edit-grupos-rel"
            color="gray"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="$router.replace(`edit/${item.id}`)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more-grupos-rel"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="
                  canDeleteItem(item) && item.relatorios_vinculados.length == 0
                "
                @click="openDialogDelete(item.id, item.titulo)"
              >
                <v-list-item-title>
                  <v-icon small> mdi-trash-can-outline </v-icon>
                  Deletar
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  (dialogCreationLog = true),
                    (dialogCreationLogData = {
                      username: item.usuario,
                      created: item.created
                    })
                "
              >
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title"
        >Deletar Grupo de Relatório #{{ dialogDeleteData.id }}</template
      >
      <template slot="body"
        >Tem certeza que deseja deletar o grupo de relatório
        <strong>{{ dialogDeleteData.titulo }}</strong
        >?</template
      >
    </dialog-delete>
  </v-container>
</template>

<script>
import GruposRelatoriosService from '@/services/GruposRelatoriosService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [powerupsActionsMixins, refreshDataMixins, tourMixins],

  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete')
  },

  data: () => ({
    powerupService: GruposRelatoriosService,
    powerupEntityName: 'Grupo de Relatório',
    search: '',
    headers: [
      {
        text: 'Grupo',
        value: 'titulo'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        width: '15%'
      }
    ],
    items: [],
    arrTour: [
      {
        element: '#btn-novo-grupo-relatorio',
        intro:
          'Clicando sobre este botão você poderá criar novos grupos de relatórios modelos.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#grupos-relatorios-historico-search',
        intro:
          'Neste campo você poderá pesquisar por grupos de relatórios modelos.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-edit-grupos-rel',
        intro:
          'Clicando sobre este botão você poderá editar seu grupo de relatório modelo.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-grupos-rel',
        intro:
          'Aqui você acessa outras opções para seu grupo de relatório modelo, como deletá-lo (caso não tenha relatórios filhos associados) e visualizar informações de criação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  created() {
    this.refreshData(this.getGruposRelatorios);
  },

  methods: {
    getGruposRelatorios() {
      this.items = [];
      this.loading = true;
      GruposRelatoriosService.getGruposRelatorios()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    deletarItem(id) {
      this.powerupService
        .deletar(id)
        .then(() => {
          this.$toast.success('Grupo de relatório excluido com sucesso.', '', {
            position: 'topRight',
            timeout: 1000,
            onClosed: () => location.reload()
          });
        })
        .catch(() => {
          this.$toast.error(`Erro ao deletar ${this.powerupEntityName}.`, '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.dialogDelete = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>
